import i18n from 'services/i18n'
import { KIT_CODE } from '../constants/admin'
import { LOCAL_STORAGE_KEYS_REGISTRATIONS } from '../constants/registrations'
import {
  formatCurrency,
  formatCurrencyWithCode,
  removeTrailingZeroes
} from './currency_formatting'

const STRIPE_ERRORS = [
  {
    pattern: 'insufficient funds',
    elementId: 'card-errors-number'
  },
  {
    pattern: 'expired',
    elementId: 'card-errors-expiry'
  },
  {
    pattern: 'security code',
    elementId: 'card-errors-cvc'
  },
  {
    pattern: 'zip code',
    field: 'address_zip'
  }
]

export const removeAlipayParams = url =>
  url.replace(
    /\??(source|client_secret|livemode|redirect_status)=[\d\w]+&?/gi,
    ''
  )

export const getStripeError = message =>
  STRIPE_ERRORS.find(error => message.match(new RegExp(error.pattern, 'gi')))

export const translateBillingFrequency = (billingFrequency, count = 1) => {
  if (billingFrequency === 'monthly') {
    return i18n.t(
      'registrations:RegistrationInfoDescription.description.month',
      'month',
      { count }
    )
  }

  return i18n.t(
    'registrations:RegistrationInfoDescription.description.year',
    'year',
    { count }
  )
}

export const translateAbbreviatedBillingFrequency = billingFrequency => {
  if (billingFrequency === 'monthly') {
    return i18n.t(
      'registrations:RegistrationInfoDescription.description.mo',
      'mo'
    )
  }

  return i18n.t(
    'registrations:RegistrationInfoDescription.description.yr',
    'yr'
  )
}

export const getPlanInfo = registrationInfo => {
  const {
    purchaseInfo,
    membershipType,
    extensionType,
    combinationType
  } = registrationInfo

  const billingFrequency =
    (membershipType && membershipType.billing_frequency) ||
    (extensionType && extensionType.billing_frequency)
  const billingFrequencyForSentence = translateBillingFrequency(
    billingFrequency
  )

  const rollingBillingFrequency =
    (membershipType &&
      membershipType.roll_plan &&
      membershipType.roll_plan.billing_frequency) ||
    (extensionType &&
      extensionType.roll_plan &&
      extensionType.roll_plan.billing_frequency)

  const rollPlan =
    (membershipType && membershipType.roll_plan) ||
    (extensionType && extensionType.roll_plan) ||
    {}

  const trialDays =
    (membershipType && membershipType.trial_period_days) ||
    (extensionType && extensionType.trial_period_days)

  const rollingPrice =
    (((membershipType &&
      membershipType.roll_plan &&
      membershipType.roll_plan.price) ||
      0) +
      ((extensionType &&
        extensionType.roll_plan &&
        extensionType.roll_plan.price) ||
        0)) /
    100

  const renewable =
    (membershipType && membershipType.renewable) ||
    (extensionType && extensionType.renewable)

  const chargeFrequency =
    (membershipType && membershipType.charge_frequency) ||
    (extensionType && extensionType.charge_frequency)

  const rollingChargeFrequency =
    (membershipType && membershipType?.roll_plan?.charge_frequency) || 1

  const price =
    ((purchaseInfo && purchaseInfo.price) ||
      (combinationType && combinationType.price) ||
      0) / 100

  const interval = translateAbbreviatedBillingFrequency(billingFrequency)
  const rollingPriceInterval = translateAbbreviatedBillingFrequency(
    rollingBillingFrequency
  )

  const rollingInterval = translateAbbreviatedBillingFrequency(
    rollingBillingFrequency
  )

  const periodNumber = membershipType?.billing_periods || chargeFrequency

  const numberOfMonthsOrYears =
    (membershipType?.billing_periods || 1) * chargeFrequency || 1

  const rollingNumberOfMonthsOrYears =
    (membershipType?.roll_plan?.billing_periods || 1) * rollingChargeFrequency

  const periodFrequency = translateBillingFrequency(
    billingFrequency,
    numberOfMonthsOrYears
  )

  const rollingPeriodFrequency = translateBillingFrequency(
    rollingBillingFrequency,
    rollingChargeFrequency
  )

  const period = `${numberOfMonthsOrYears} ${periodFrequency}`

  const periodForSentence =
    numberOfMonthsOrYears === 1
      ? `${periodFrequency}`
      : `${numberOfMonthsOrYears} ${periodFrequency}`

  const rollingPeriodForSentence =
    rollingNumberOfMonthsOrYears === 1
      ? `${rollingPeriodFrequency}`
      : `${rollingNumberOfMonthsOrYears} ${rollingPeriodFrequency}`

  const billingPeriods =
    membershipType?.billing_periods || (membershipType?.roll_plan ? 1 : null)

  const tiered = membershipType && membershipType.code === KIT_CODE

  const recurring = ['repeating', 'forever'].includes(
    purchaseInfo?.couponInfo?.duration
  )

  const entityType =
    combinationType?.entity_type ||
    membershipType?.entity_type ||
    extensionType?.entity_type

  const percentageOff =
    combinationType?.percentage_off ||
    membershipType?.percentage_off ||
    extensionType?.percentage_off

  const stripePlan = membershipType?.stripe_plan || extensionType?.stripe_plan
  const currency = stripePlan?.currency || 'usd'

  const billingChangeWillOccur =
    !!rollPlan?.price &&
    (price !== rollingPrice ||
      interval !== rollingInterval ||
      chargeFrequency !== rollingChargeFrequency)

  const formattedPriceDueNow = removeTrailingZeroes(
    formatCurrencyWithCode(price, currency)
  )

  const monthlyPriceDivider = chargeFrequency > 1 ? chargeFrequency : 12

  const formattedPriceAsMonthly = `${removeTrailingZeroes(
    formatCurrency(
      billingFrequency === 'yearly' || chargeFrequency > 1
        ? price / monthlyPriceDivider
        : price,
      currency
    )
  )}/${i18n.t(
    'registrations:RegistrationInfoDescription.description.mo',
    'mo'
  )}`

  return {
    billingChangeWillOccur,
    billingFrequency,
    billingFrequencyForSentence,
    chargeFrequency,
    billingPeriods,
    rollPlan,
    trialDays,
    renewable,
    price,
    interval,
    rollingPrice,
    rollingPriceInterval,
    rollingPeriodForSentence,
    rollingNumberOfMonthsOrYears,
    rollingBillingFrequency,
    rollingChargeFrequency,
    rollingInterval,
    period,
    periodForSentence,
    periodNumber,
    tiered,
    recurring,
    entityType,
    percentageOff,
    currency,
    formattedPriceDueNow,
    formattedPriceAsMonthly
  }
}

export const getStoredRegistrationInfo = () => {
  return window.localStorage.getItem(LOCAL_STORAGE_KEYS_REGISTRATIONS.APT_TOKEN)
}

export const isShortFormForCountryRegionAvailable = countryShortForm => {
  const short_form_not_Avai = {
    PF: 'PF',
    KY: 'KY',
    TF: 'TF',
    CK: 'CK',
    FK: 'FK',
    FO: 'FO',
    GF: 'GF',
    GI: 'GI',
    GP: 'GP',
    GU: 'GU',
    GG: 'GG',
    HM: 'HM',
    HK: 'HK',
    JE: 'JE',
    KI: 'KI',
    MO: 'MO',
    MQ: 'MQ',
    MS: 'MS',
    NC: 'NC',
    NU: 'NU',
    NF: 'NF',
    MP: 'MP',
    PN: 'PN',
    PR: 'PR',
    RE: 'RE',
    MF: 'MF',
    PM: 'PM',
    SX: 'SX',
    GS: 'GS',
    TK: 'TK',
    TC: 'TC',
    IM: 'IM',
    KZ: 'KZ',
    ES: 'ES'
  }

  return !short_form_not_Avai[countryShortForm]
}

export const isDateLessThan60DaysOld = dateString => {
  if (!dateString || typeof dateString !== 'string') {
    return false
  }
  const date = new Date(dateString)
  const now = new Date()

  const differenceInMillis = now.getTime() - date.getTime()

  const sixtyDaysInMillis = 60 * 24 * 60 * 60 * 1000
  return differenceInMillis < sixtyDaysInMillis
}

export const subscriptionFrequency = (billingFrequency, chargeFrequency) => {
  if (billingFrequency === 'yearly') {
    return i18n.t('account:Subscriptions.frequencyYr', '/yr')
  }

  if (chargeFrequency === 1) {
    return i18n.t('account:Subscriptions.frequencyMo', '/mo')
  }

  return i18n.t(
    'account:Subscriptions.multiMonthFrequencyShort',
    '/ {{chargeFrequency}} mo',
    {
      chargeFrequency
    }
  )
}
